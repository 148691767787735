<template>
  <el-dialog
    title="详情"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
        label-suffix=":"
      >
        <el-form-item label="" prop="">
          <el-date-picker
            v-model="time"
            type="month"
            placeholder="选择月"
            format="yyyy 年 MM 月"
            value-format="yyyyMM"
            @change="monthChange"
          ></el-date-picker>
        </el-form-item>
        <div v-loading="loading">
          <el-form-item label="员工姓名" prop="">
            <el-input v-model="form.user_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="考勤组" prop="">
            <el-input v-model="form.attend" disabled></el-input>
          </el-form-item>
          <el-form-item label="部门" prop="">
            <el-input v-model="form.depart" disabled></el-input>
          </el-form-item>

          <el-table stripe :data="list" style="margin-bottom: 15px">
            <el-table-column
              v-for="(item, tableIndex) in columns"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            ></el-table-column>
          </el-table>

          <el-form-item label="应出勤" prop="">
            <el-input v-model="form.should" disabled></el-input>
          </el-form-item>
          <el-form-item label="实际出勤" prop="">
            <el-input v-model="form.normal" disabled></el-input>
          </el-form-item>
          <el-form-item label="迟到" prop="">
            <el-input v-model="form.late" disabled></el-input>
          </el-form-item>
          <el-form-item label="早退" prop="">
            <el-input v-model="form.before" disabled></el-input>
          </el-form-item>
          <el-form-item label="缺勤" prop="">
            <el-input v-model="form.absence" disabled></el-input>
          </el-form-item>
          <el-form-item label="请假" prop="">
            <el-input v-model="form.leave" disabled></el-input>
          </el-form-item>
          <el-form-item label="缺卡" prop="">
            <el-input v-model="form.miss_card" disabled></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="dialog-footer">
      <el-button @click="handleExport">导出</el-button>
      <el-button @click="showDialog = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { downloadFile, postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        showDialog: false,
        id: null,
        time: null,
        loading: false,
        form: {},
        list: [],
        columns: [
          {
            order: 1,
            label: '日期',
            prop: 'day',
            width: '',
          },
          {
            order: 2,
            label: '上午打卡',
            prop: 'am',
            width: '',
          },
          {
            order: 3,
            label: '下午打卡',
            prop: 'pm',
            width: '',
          },
          {
            order: 4,
            label: '考勤结果',
            prop: 'result',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      showDialog(v) {
        v && this.fetchData()
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true

        let { data } = await postAction(
          '/taskAdmin/attendance/attend-statistics-info',
          {
            month: this.time,
            user_id: this.id,
          }
        )
        this.list = data.list
        this.form = data.info
        this.loading = false
      },
      monthChange(v) {
        this.time = v
        this.fetchData()
      },
      handleExport() {
        downloadFile(
          '/taskAdmin/attendance/attend-statistics-info-export',
          `考勤统计(${this.form.user_name}).xlsx`,
          {
            month: this.time,
            user_id: this.id,
          }
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>

<template>
  <!-- NAME[epic=绩效] 考勤统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="month">
        <el-date-picker
          v-model="form.month"
          type="month"
          placeholder="选择月"
          format="yyyy 年 MM 月"
          value-format="yyyyMM"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="depart_id">
        <el-select
          v-model="form.depart_id"
          clearable
          style="width: 140px"
          placeholder="请选择部门"
        >
          <el-option
            v-for="(i, idx) in groupSelect"
            :key="idx"
            :value="i.id"
            :label="i.text"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="attend_id">
        <el-select
          v-model="form.attend_id"
          clearable
          style="width: 140px"
          placeholder="考勤分组"
        >
          <el-option
            v-for="(i, idx) in attendanceSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="user_id">
        <el-select
          v-model="form.user_id"
          clearable
          filterable
          style="width: 140px"
          placeholder="员工姓名、电话"
        >
          <el-option
            v-for="(i, idx) in peopleSelect"
            :key="idx"
            :value="i.user_id"
            :label="i.user_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="clearForm">清空</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出汇总</el-button>
      </el-form-item>

      <el-form-item label="" prop="">
        <el-button @click="handleExport2">导出明细</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="100px">
        <template #default="{ row }">
          <el-button type="text" @click="handleCheck(row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <detail ref="detail"></detail>
  </div>
</template>
<script>
  import _ from 'lodash'
  import {
    getList,
    peopleList,
    attendanceList,
    departmentList,
  } from '@/api/performance'
  import Detail from './detail'
  import { downloadFile } from '@/api/Employee'
  export default {
    name: 'AttendanceSummary',
    components: {
      Detail,
    },
    data() {
      return {
        loading: false,
        total: 0,
        peopleSelect: [],
        groupSelect: [],
        attendanceSelect: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          month: '', //月：202011
          depart_id: '', //部门id
          attend_id: '', //考勤组id
          user_id: '', //员工id
          pageNo: 1, //当前页
          pageSize: 10, //每页条数
          sort: '', //排序字段
          order: '', //排序规则
        },
        list: [],
        checkList: [
          '员工名称',
          '考勤组',
          '部门',
          '应出勤天数',
          '实际出勤天数',
          '正常出勤天数',
          '迟到次数',
          '早退次数',
          '缺卡次数',
          '缺勤天数',
          '请假天数',
        ],
        columns: [
          {
            order: 1,
            label: '员工名称',
            prop: 'name',
            width: '',
          },
          {
            order: 2,
            label: '考勤组',
            prop: 'attend_name',
            width: '',
          },
          {
            order: 3,
            label: '部门',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 4,
            label: '应出勤天数',
            prop: 'should',
            width: '',
          },
          {
            order: 5,
            label: '实际出勤天数',
            prop: 'actual',
            width: '',
          },
          {
            order: 6,
            label: '正常出勤天数',
            prop: 'normal',
            width: '',
          },
          {
            order: 7,
            label: '迟到次数',
            prop: 'late',
            width: '',
          },
          {
            order: 8,
            label: '早退次数',
            prop: 'before',
            width: '',
          },
          {
            order: 8.6,
            label: '缺卡次数',
            prop: 'miss_card',
            width: '',
          },
          {
            order: 9,
            label: '缺勤天数',
            prop: 'absence',
            width: '',
          },
          {
            order: 10,
            label: '请假天数',
            prop: 'leave',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      // 初始化当前月份
      let y = new Date().getFullYear()
      let m =
        (new Date().getMonth() + 1 < 10 ? '0' : '') +
        (new Date().getMonth() + 1)
      this.form.month = y + '' + m
      this.fetchData()
      // 员工下拉
      peopleList({ pageSize: -1 }).then((res) => {
        console.log(res)
        this.peopleSelect = res.data
      })
      attendanceList().then((res) => {
        this.attendanceSelect = res.data
      })
      // 部门下拉
      departmentList().then((res) => {
        this.groupSelect = res.data.list
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, code, msg, totalCount } = await getList(this.form)
        console.log(data)
        this.total = totalCount
        this.list = data
        this.loading = false
      },
      clearForm() {
        this.$refs.form.resetFields()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleQuery() {
        if (this.form.month == '') {
          this.$message.error('请输入月份')
          return false
        } else {
          this.form.pageSize = 10
          this.form.pageNo = 1
          this.fetchData()
        }
      },
      handleCheck(row) {
        console.log(row, this.form.month)
        this.$refs.detail.id = row.id
        this.$refs.detail.time = this.form.month
        this.$refs.detail.showDialog = true
      },
      handleExport() {
        downloadFile(
          '/taskAdmin/attendance/attend-statistics-export',
          '考勤汇总.xlsx',
          this.form
        )
      },
      handleExport2() {
        downloadFile(
          '/taskAdmin/attendance/attend-statistics-result-export',
          '考勤明细.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
